.services-container {
    padding-top: 90px; /* Adjust this value if needed */
    padding: 50px;
    max-width: 800px;
    margin: auto;
    text-align: center;
  }  

.services-title {
  font-size: 3em;
  color: #249da1;
  margin-bottom: 30px;
  font-weight: bold;
  text-transform: uppercase;
}

.service-section {
  margin-bottom: 50px;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(135deg, #eef9ff, #d8f3fc);
  box-shadow: 0px 8px 15px rgba(0, 47, 49, 0.2);
}

.service-heading {
  font-size: 2em;
  color: #006064;
  margin-bottom: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.service-description {
  font-size: 1.2em;
  color: #333;
  line-height: 1.6;
}
