.jobs-container {
    padding-top: 90px; /* Adjust this value if needed */
    padding-bottom: 50px;
    max-width: 800px;
    margin: auto;
    text-align: center;
    min-height: 80vh; /* Ensures content pushes the footer down */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .jobs-title {
    font-size: 3em;
    color: #249da1;
    margin-bottom: 20px;
  }
  
  .jobs-description {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 40px;
  }
  
  .job-role {
    margin-bottom: 30px;
  }
  
  .job-role h2 {
    font-size: 1.8em;
    color: #006064;
    margin-bottom: 10px;
  }
  
  .job-role p {
    font-size: 1.1em;
    color: #555;
    line-height: 1.6;
  }
  
  .application-info {
    font-size: 1.2em;
    color: #249da1;
    margin-top: 30px;
  }
  
  .application-info a {
    color: #006064;
    text-decoration: none;
  }
  
  .application-info a:hover {
    text-decoration: underline;
  }
  